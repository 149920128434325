import React, { Component, Fragment, useCallback, useEffect, useRef, useState, createContext, useContext } from 'react';
import { Container, Row, Col, Nav, Navbar, NavItem, NavLink, Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';
import $ from 'jquery';
import cx from 'classnames';

import './css/common.css';
import './css/header.css';
import './css/lightmode.css';
import './css/others.css';
import './css/style.scss';

import { Request, usePrevious, useWindowSize } from 'utils';


const ThemeContext = createContext('dark');


function Home(props){

    let [isSectionBVisible, setIsSectionBVisible] = useState(false);
    let [isSectionDVisible, setIsSectionDVisible] = useState(false);
    let [isSectionFVisible, setIsSectionFVisible] = useState(false);
    let [isSectionJVisible, setIsSectionJVisible] = useState(false);

    let last_scroll_position = useRef(window.scrollY);
    const [isValidPeriod, setIsValidPeriod] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        Request.post('getIpAdminDetailsForSelfReg',{}).then((res)=>{
            let ress = res.meta.data
            setIsValidPeriod(ress?.is_valid_period)
        })

    }, []);

    const [width, height] = useWindowSize();


    const isInViewport = function(ref) {
        var top_of_element = $(ref).offset().top;
        var bottom_of_element = $(ref).offset().top + $(ref).outerHeight();
        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        var top_of_screen = $(window).scrollTop();

        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
            return true;
        } else {
            return false;
        }
    }

    const slideUpAnimation = function(ref, duration = 1500) {
        $(ref).css({
            "margin-top": "300px",
            "opacity": "0"
        }).animate({
            "margin-top": "0px",
            "opacity": "1"
        }, duration);
    }


    //let [lastScrollPosition, setLastScrollPosition] = useState(window.scrollY);

    const handleScroll = useCallback(() => {

        if (last_scroll_position.current > window.scrollY) return last_scroll_position.current = window.scrollY
        last_scroll_position.current = window.scrollY

        if (isInViewport(".sectionB-anim")) {
            if (!isSectionBVisible) {
                setIsSectionBVisible(true);
                slideUpAnimation(".sectionB-anim")
            }
        } else {
            if (isSectionBVisible) {
                setIsSectionBVisible(false);
            }
        }

        if (isInViewport(".sectionD-anim")) {
            if (!isSectionDVisible) {
                setIsSectionDVisible(true);
                slideUpAnimation(".sectionD-anim")
            }
        } else {
            if (isSectionDVisible) {
                setIsSectionDVisible(false);
            }
        }

        if (isInViewport(".sectionF-anim")) {
            if (!isSectionFVisible) {
                setIsSectionFVisible(true);
                slideUpAnimation(".sectionF-anim")
            }
        } else {
            if (isSectionFVisible) {
                setIsSectionFVisible(false);
            }
        }

        const team = ["thillai", "sethuraman",  "rohit"]

        if (isInViewport(".sectionI-anim")) {
            if (!isSectionJVisible) {
                setIsSectionJVisible(true);
                team.forEach((member, i) => {
                    slideUpAnimation("#" + member, i * 500)
                })
            }
        } else {
            if (isSectionJVisible) {
                setIsSectionJVisible(false);
            }
        }

    }, [last_scroll_position.current]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => { window.removeEventListener('scroll', handleScroll); };

    }, [handleScroll]);

    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
    const toggleMobileMenu = (isOpen) => {
        setIsOpenMobileMenu(!isOpen);
    };

    return (
            <ThemeContext.Provider value={props.theme==1?"dark":"light"}>
                <div className={props.theme==1?"theme-dark":"theme-light"}>
                    {/***--==========================
                     Top Bar
                    ============================--***/}
                    <header className="header">
                        <Navbar>
                            <span className="nav-logo">IDIP</span>
                            {/**<div className="nav-menu">**/}
                            <ul className={cx("nav-menu", {'active': isOpenMobileMenu})}>
                                {/**<li className="nav-item" onclick="scrollToMedia('mediaSection')">***/}
                                <li className="nav-item">
                                    <a href="#" className="nav-link">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#sectionC" className="nav-link">Features</a>
                                </li>
                                <li className="nav-item">
                                    {/**<a href="#" className="nav-link" onclick="scrollToMedia('aboutUs')">About us</a>***/}
                                    <a href="#sectionB" className="nav-link">About us</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">Contact us</a>
                                </li>
                            </ul>
                            <div className="nav-buttons" style={{"display": "flex", "alignItems": "center"}}>
                                <span className="material-icons" id="toggleTheme" onClick={() => props.toggleTheme(!props.theme)}>
                                    {props.theme == 1 ? "light_mode" : "dark_mode"}
                                </span>
                                {width > 950 ? (<><span style={{"width": "25px"}}></span>
                                <button className="login-btn" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); }}>Login</button>
                                {/* {isValidPeriod && <>
                                <button className="free-trail-btn" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); props.setActiveTabFn(4); }}>Self Registration</button>
                                <span style={{"width": "10px"}}></span>
                                </>} */}
                                {/* <button className="free-trail-btn" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); props.setActiveTabFn(2); }}>Start free trial</button> */}
                                </>) : null }
                            </div>
                            <a href="" onClick={(e) => {e.preventDefault(); props.toggleTheme(!props.theme)}} className="d-lg-none d-xl-none d-md-none d-sm-block d-block mobile-theme-change-btn"><span className="material-icons">{props.theme == 1 ? "light_mode" : "dark_mode"}</span></a>
                            <div onClick={() => toggleMobileMenu(isOpenMobileMenu)} className={cx("hamburger d-lg-none d-xl-none d-md-none d-sm-block d-block", {"active": isOpenMobileMenu})}>
                                <span className="bar"></span>
                                <span className="bar"></span>
                                <span className="bar"></span>
                            </div>
                        </Navbar>
                    </header>
                    <div id="sectionA"><SectionA {...props} screenWidth={width} /></div>
                    <div id="sectionB"><SectionB/></div>
                    <div id="sectionC"><SectionC /></div>
                    <div id="sectionD"><SectionD /></div>
                    <div id="sectionE"><SectionE /></div>
                    <div id="sectionF"><SectionF/></div>
                    <div id="sectionG"></div>
                    <div id="sectionH"></div>
                    <div id="sectionI"><SectionI/></div>
                    <div id="sectionJ"><SectionJ/></div>
                    <div id="sectionK"></div>
                    <div id="sectionL"><SectionL/></div>
                    <div id="footer1"><Footer/></div>
                    <LoginPopupContent/>
                </div>
            </ThemeContext.Provider>
        );
}

export default Home;

function LoginPopupContent(){
    return (
        <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document" style={{"width": "70vw"}}>
                <div className="modal-content row" style={{"height": "80vh", "borderRadius": "30px", "backgroundColor": "#1D3348", "padding": "0px"}}>
                    <div className="col-md-6 col-sm-12 col-12 bg_image" style={{"height": "100%"}}>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/login/login_bg.png`} style={{"height": "100%","width": "100%", "objectFit": "cover"}} alt="" />
                    </div>
                    <div className="col-md-6 col-sm-12 col-12" style={{"height": "100%"}}>
                        <div className="flex-col-center text-theme" style={{"color": "white","height": "100%","alignItems": "flex-start","gap": "7px","padding": "30px 70px"}}>
                            <div style={{"fontSize": "24px", "fontWeight": "600"}}>Login</div>
                            <div style={{"height": "1px","backgroundColor": "white","width": "100%","margin": "7px 0px"}}></div>
                            <div style={{"height": "20px"}}></div>
                            <div style={{"fontSize": "16px", "fontWeight": "300"}}>Email Address</div>
                            <input type="text" required placeholder="Enter Email Address" className="inputBox"/>
                            <div style={{"height": "8px"}}></div>
                            <div style={{"fontSize": "16px","fontWeight": "300"}}>Password</div>
                            <input type="text" required placeholder="Enter Password" className="inputBox"/>
                            <div style={{"height": "20px"}}> </div>
                            <div className="ip" style={{"textAlign": "center","width": "100%","fontWeight":"500","color": "rgb(212, 212, 212)"}}>Your IP address is 192.168.9.1</div>
                            <div style={{"height": "20px"}}> </div>
                            <button className="loginBtn">Login</button>
                            <div style={{"color": "#61C7C1","fontWeight": "500","textAlign": "center","width": "100%"}}>
                                Forgot your Password?
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

function SectionA(props){

    const textRef = useRef(null);

    useEffect(() => {
        let text = ["Water & Sanitation ", 'Roads'];
        let counter = 0;
        let elem = document.getElementById("changeText");
        textRef.current.innerHTML = text[counter];
        counter++;
        let intrlLogic = setInterval(() => {
            //elem.innerHTML = text[counter];
            textRef.current.innerHTML = text[counter];
            counter++;
            if (counter >= text.length) counter = 0;
        }, 2000);

        return () => { clearInterval(intrlLogic); }

    }, [textRef]);

    return (
        <div className="sectionA">
            <div className="centerBody">
                <h1 className="titleText text-theme"> Integrated Database for Infrastructure </h1>
                <span style={{"height": "10px"}}></span>
                <div className="row titleText" style={{"width": "100%"}}>
                    <div className="col-6 text-theme px-0" style={{"textAlign": "right"}}>
                        Projects -
                    </div>
                    <div className="col-6 px-0" style={{"textAlign": "left"}}>
                        <span id="changeText" ref={textRef} style={{"color": "#61C7C1"}}></span>
                    </div>
                </div>
                <span style={{"height": "40px"}}></span>

                <p className="description text-theme">
                    Get complete project data for  sectors of Infrastructure Projects: Road, water & sanitation
                </p>
                <br/>
                <center className="flex-row">
                    {/* <button className="btn-outline text-theme button-outline-theme">
                        Learn More
                    </button> */}
                    {/* {props.screenWidth > 950 ? (<button className="btn-filled button-filled-theme" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); }}>Sign up now!</button>) : null} */}
                </center>
            </div>
        </div>
    );
}

function SectionB(){ //What is IDIP?

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <>
            <div className="body aboutUs sectionB">
                <div className="sectionB-anim">
                    <h1 className="idipTitle text-theme">What is IDIP?</h1>
                    <div style={{"height": "20px"}}></div>
                    <div className="idipContent text-theme">
                        Unlike a traditional physical infrastructure asset, which has local benefits, IDIP involves creation of an
                        ecosystem that provides benefits at a sectoral level and across different stakeholders. The data coverage
                        extends to projects across the whole of
                        the country, and covers multiple sectors such as roads, water & Sanitation and
                        so on. The IDIP initiative would benefit policy makers, investors, developers, managers, research scholars,
                        faculty members, students,
                        advocacy groups, international agencies, and so on.<br /><br /> Before IDIP, data availability on
                        infrastructure projects in the country was very limited. Though there were several sources, they were not
                        comprehensive. They were
                        either limited by sector or type of projects (only PPP projects or only central government projects and so
                        on). These sources did not cover the entire project lifecycle, and mostly were restricted to development and
                        construction phases. There
                        was also large scale hoarding of data, and it was not easily accessible, denying a level playing field, that
                        benefited a few large players. IDIP would democratize access to data and information in the infrastructure
                        sector. IDIP <br /><br /> Integrated Database of Infrastructure Projects (IDIP) in India is an intelligence
                        and analytics platform that provides comprehensive and updated information on infrastructure projects across
                        the project lifecycle, covering development,
                        construction and operational phases. IDIP is a unique platform that meets the information and decision
                        support requirements of policy makers, practitioners, investors and other stakeholders associated with
                        development and operation of infrastructure
                        assets.
                    </div>
                    <div style={{"height":"40px"}}></div>
                    <center>
                        {/**<button className="btn-filled button-filled-theme" data-toggle="modal" data-target="#freeTrailModal">**/}
                        <button className="btn-filled button-filled-theme" onClick={toggle}>
                            Watch Video &nbsp;
                            <span className="material-icons"> play_circle_outline</span>
                        </button>
                    </center>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} centered size="xl" className="whatisidip_video_container">
                <ModalBody style={{"height": "83vh", "borderRadius": "0", "backgroundColor": "#1D3348", "padding": "0px"}}>
                    <video width="100%" height="100%" controls autoPlay={modal}>
                        <source src={`${process.env.PUBLIC_URL}assets/idip_av.mp4`} type="video/mp4"/>
                        Your browser does not support HTML video.
                    </video>
                </ModalBody>
            </Modal>
        </>
    );
}

function SectionC() {

    const [imgIndx, setImgIndx] = useState(0);

    const _themeContext = useContext(ThemeContext);
    const prevTheme = usePrevious(_themeContext);

    const tabImages = [`${process.env.PUBLIC_URL}assets/section_c/Feature_1.gif`, `${process.env.PUBLIC_URL}assets/section_c/Feature_2.gif`, `${process.env.PUBLIC_URL}assets/section_c/feature_3.png`];
    const tabImagesLight = [`${process.env.PUBLIC_URL}assets/section_c/Feature_1_light.gif`, `${process.env.PUBLIC_URL}assets/section_c/Feature_2_light.gif`,`${process.env.PUBLIC_URL}assets/section_c/feature_3_light.png`];

    const [tabImg, setTabImg] = useState(_themeContext == 'dark' ? `${process.env.PUBLIC_URL}assets/section_c/Feature_1.gif`: `${process.env.PUBLIC_URL}assets/section_c/Feature_1_light.gif`);

    const onTabChange = function(index){
        setImgIndx(index);
        const isDarkMode = localStorage.getItem('dark-mode') ?? 'true';
        setTabImg(_themeContext == 'dark' ? tabImages[index] : tabImagesLight[index]);

        //$("#tabImage").attr("src", isDarkMode == 'true' ? tabImages[index] : tabImagesLight[index]).css({
        $("#tabImage").css({
            opacity: 0
        }).animate({
            opacity: 1
        }, 1000);

    };

    useEffect(() => {
        onTabChange(imgIndx);
    }, [_themeContext != prevTheme]);

    return (
    <div className="body" style={{"minHeight": "750px",paddingTop:"150px"}}>
        <span style={{"height": "30px"}}></span>
        <div className="flex-row-wrap">
            <div style={{"flex": "1.5", "minWidth": "300px"}}>
                <h2 className="text-theme" style={{"fontSize": "20px", "fontWeight": 500}}> Get insights on different projects over
                    their evolution from
                    the conception stage.
                </h2>
                <div style={{"height": "20px"}}></div>

                <div className="accordion accordion-flush" id="accordionExample" style={{"textAlign": "left"}}>
                    <div className="accordion-item" onClick={() => onTabChange(0)}>
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span className="material-icons" style={{"marginRight": "20px"}}>circle</span> Project monitoring
                                through its life-cycle
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body text-theme" style={{"paddingLeft": "80px"}}>
                                Track projects and identify bottlenecks in different stages- from announcement to
                                implementation and completion to commissioning
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" onClick={() => onTabChange(1)}>
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <span className="material-icons" style={{"marginRight": "20px"}}>circle</span> Oversight of delivery
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body text-theme">
                                <ul>
                                    <span>• </span><span>Sector-wise successful infrastructure projects across
                                        states</span><br/>
                                    <span>• </span><span>Which states lead in on time completion of projects?</span><br/>
                                    <span>• </span><span>Project cost over-runs and its causative factors</span><br/>
                                    <span>• </span><span>Efficiency of infrastructure service providers</span><br/>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item" onClick={() => onTabChange(2)}>
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span className="material-icons" style={{"marginRight": "20px"}}>circle</span> Evaluation of
                                contract performance
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div className="accordion-body text-theme">
                                <ul>
                                    <span>• </span><span>Analysis of successful projects</span><br/>
                                    <span>• </span><span>Understand causative factors behind failed projects</span><br/>
                                    <span>• </span><span>Risk assessment of various contractors</span><br/>
                                    <span>• </span><span>Performance of SPVs post completion</span><br/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{"flex": 1, "minWidth": "200px"}}>
                <img id="tabImage" className="fadein newfade" src={tabImg} width="100%" alt=""/>
            </div>
        </div>
    </div>);
}

function SectionD(){
    const [refresh,setRefresh]=useState(false)
    const [counted, setCounted] = useState(0);
    const section_d_tab_ids = [
        "roads",
        "metro",
        "airports",
        "ports",
        "water",
        "solid_waste",
    ];

    const openSectionDTab = function(tab_id){
        setCounted(0)
            if(tab_id==="roads"){
                Request.post('getRoadNoticeDetails',{},2).then((res)=>{
                    if(res.meta.data) {
                        $('#tp').html(0).attr('data-count',res.meta.data.total_project);
                        $('#ul').html(0).attr('data-count',res.meta.data.total_ui_project);
                        $('#tc').html(0).attr('data-count',Math.round(res.meta.data.total_cost));
                        handleScroll()
                    }
                })
            }else if(tab_id==="water"){
                Request.post('getWSNoticeDetails',{},3).then((res)=>{
                    if(res.meta.data) {
                        $('#tp').html(0).attr('data-count',res.meta.data.total_project);
                        $('#ul').html(0).attr('data-count',res.meta.data.total_ui_project);
                        $('#tc').html(0).attr('data-count',Math.round(res.meta.data.total_cost));
                        handleScroll()
                    }
                })
            }
        $(".tabSectionDContent").css("display", "none"); // Hide all content

        section_d_tab_ids.forEach(each_tab_id => {
            if (each_tab_id == tab_id) $("#tb_link_" + each_tab_id).addClass("active");
            else $("#tb_link_" + each_tab_id).removeClass("active");
        });

        $("#tb_content_" + tab_id).css({
            "display": "block",
            "margin-top": "100px",
            opacity: 0
        }).animate({
            "margin-top": "0px",
            opacity: 1
        }, 500);

    };



    const handleScroll = () => {
        console.log(counted)
        var oTop = $('#counter').offset().top - window.innerHeight;
        if (counted == 0 && $(window).scrollTop() > oTop) {
            $('.count').each(function () {

                var $this = $(this),
                    countTo = $this.attr('data-count');
                    console.log(countTo)
                $({
                    countNum: $this.text()
                }).animate({
                    countNum: countTo
                }, {
                    duration: 4000,
                    easing: 'swing',
                    step: function () {
                        $this.text(Math.floor(this.countNum));
                    },
                    complete: function () {
                        $this.text(this.countNum.toLocaleString('en-IN'));
                        //alert('finished');
                    }
                });
            });
            setCounted(0);
        }
    };


    useEffect(() => {

        $(".tabSectionDContent").css("display", "none"); // Hide all content
        $(".tbSectionDLinks").removeClass("active"); // Hide all content

        openSectionDTab('roads');

        // Remove the transition class
        const counternumber = document.querySelector('.counternumber');
        counternumber.classList.remove('counternumber-transition');

        // Create the observer, same as before:
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    counternumber.classList.add('counternumber-transition');
                    return;
                }
            });
        });

        observer.observe(document.querySelector('.counternumber-wrapper'));

         window.addEventListener('scroll', handleScroll);


         return () => { window.removeEventListener('scroll', handleScroll); };

    }, []);


    return (
        <div className="body">
            <div className="tab d-flex justify-content-center">
                <button id="tb_link_roads" className="tbSectionDLinks" onClick={() =>{ openSectionDTab('roads')}}>Roads</button>
                {/* <button id="tb_link_metro" className="tbSectionDLinks" onClick={() => openSectionDTab('metro')}>Metro</button> */}
                {/* <button id="tb_link_airports" className="tbSectionDLinks" onClick={() => openSectionDTab('airports')}>Airports</button>
                <button id="tb_link_ports" className="tbSectionDLinks" onClick={() => openSectionDTab('ports')}>Ports</button> */}
                <button id="tb_link_water" className="tbSectionDLinks" onClick={() => {openSectionDTab('water')}}>Water & Sanitation</button>
                {/* <button id="tb_link_solid_waste" className="tbSectionDLinks" onClick={() => openSectionDTab('solid_waste')}>Solid Waste Management</button> */}
            </div>
            <div id="tb_content_roads" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>GoI aims to hit 2-lakh kms of national highway by 2025, with investments also
                    planned in other roads including rural roads. Deep-dive into the progress of road projects including PPP
                    along with SPV financials, post commissioning of the project.
                </p>
            </div>
            <div id="tb_content_metro" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>
                    Rapid Urbanisation demands modern public mobility with increasing private participation. Monitor the
                    estimated investment of USD 26 billion dollars planned in Metro across cities during 2020-25.
                </p>
            </div>

            <div id="tb_content_airports" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>
                    The GoI envisages an investment of INR 91,000 crore till 2025 in constructing new airports & expanding
                    existing ones. Monitor the AAI led PPP for financing the projects & its finances post commissioning.
                </p>
            </div>
            <div id="tb_content_ports" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>
                    Sagarmala initiative is expected to give a big-boost to investment in ports with over USD 82 billion
                    envisaged through various models including PPP. Take a deep-dive of upcoming port projects.

                </p>
            </div>
            <div id="tb_content_water" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>
                    Swacch Bharat Mission (SBM) & National Rural Drinking Water Program (NRDWP) are making substantial
                    investments & efforts in ensuring safe drinking water & sanitation. Monitor the projects that have an impact
                    on the communities at smaller geographies across
                    India.
                </p>
            </div>
            <div id="tb_content_solid_waste" className="tabSectionDContent text-theme">
                <p style={{"margin":"20px 0px"}}>
                    Rapid urbanisation & economic growth demands investment to manage & treat urban municipal waste. Monitor the
                    PPP to manage & treat urban waste across India
                </p>
            </div>
            <div className="sectionD-anim">
                <div className="row counternumber-wrapper" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/section_d/circle_bg.svg)`, "backgroundRepeat": "no-repeat", "backgroundPosition": "center"}}>
                    <div className="col-lg-6 counternumber counternumber-transition">
                        <div className="flex-col-center" style={{"margin": "0px 100px"}}>
                            <div className="stat-box">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_d/stats.png`} height="20px" alt=""/>
                                <span id="counter">
                                    <li>
                                        <span className="count percent" id='tp' style={{"fontSize": "40px", "fontWeight": 600}}data-count="1537">0</span>
                                        <span style={{"fontSize": "40px", "fontWeight": 600}}>+</span>
                                    </li>
                                </span>
                                <span className="stat-desc">Total Projects</span>
                            </div>
                            <div className="stat-box">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_d/stats.png`} height="20px" alt=""/>
                                <span id="counter">
                                    <li>
                                        <span className="count percent" id='ul' style={{"fontSize": "40px", "fontWeight": 600}} data-count="748">0</span>
                                    </li>
                                </span>
                                <span className="stat-desc">Under implementation</span>
                            </div>
                            <div className="stat-box">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_d/stats.png`} height="20px" alt=""/>
                                <span id="counter">
                                    <li>
                                        <span className="count percent" id='tc' style={{"fontSize": "40px","fontWeight": 600}} data-count="816254">0</span>
                                        <span style={{"fontSize": "40px", "fontWeight": 600}}> Cr</span>
                                    </li>
                                </span>
                                <span className="stat-desc">Total Cost</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 flex-col-center ">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/section_d/map.svg`} width="70%" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );

}


function SectionE(){

    const tab_ids = ['track', 'prepare', 'analyze', 'visualize'];

    const openSectionETab = function(tab_id){
        $(".tbContent").css("display", "none"); // Hide all content

        tab_ids.forEach(each_tab_id => {
            if (each_tab_id == tab_id) $("#tb_link_" + tab_id).addClass("active");
            else $("#tb_link_" + each_tab_id).removeClass("active");
        })

        $("#tb_content_" + tab_id).css({
            "display": "block",
            "margin-top": "100px",
            opacity: 0
        }).animate({
            "margin-top": "0px",
            opacity: 1
        }, 500);
    };

    useEffect(() => {

        $(".tbContent").css("display", "none"); // Hide all content
        $(".tbLinks").removeClass("active"); // Hide all content

        $("#tb_link_track").click();

    }, []);

    return (
        <div className="body" style={{"minHeight": "900px"}}>
            <div className="tab" style={{"display": "flex", "justifyContent": "center"}}>
                <button id="tb_link_track" className="tbLinks text-theme" onClick={()=>openSectionETab('track')}>Track</button>
                <button id="tb_link_prepare" className="tbLinks text-theme" onClick={()=>openSectionETab('prepare')}>Prepare</button>
                <button id="tb_link_analyze" className="tbLinks text-theme" onClick={()=>openSectionETab('analyze')}>Analyze</button>
                <button id="tb_link_visualize" className="tbLinks text-theme" onClick={()=>openSectionETab('visualize')}>Visualize</button>
            </div>

            <span style={{"height": "60px"}}></span>

            <div id="tb_content_track" className="tbContent text-theme" style={{"padding": "0px"}}>
                <div className="row">
                    <div className="col-lg-7">
                        <img className="tab_img_track" src={`${process.env.PUBLIC_URL}assets/section_e/Track/track.gif`} width="90%" alt="" style={{"borderRadius": "30px", "boxShadow": "0px 0px 20px 0px #61C7C1", "border": "none"}}/>
                    </div>
                    <div className="col-lg-5">
                        <div className="flex-col tabRightContent tb_right_content_track">
                            <span className="tButton">
                                <strong>Track</strong>
                            </span>
                            <span style={{"height": "10px"}}></span>
                            <div style={{"fontSize": "25px", "fontWeight": 600}}>
                                Track <span style={{"color": "#61C7C1"}}>project events </span> and <span style={{"color": "#61C7C1"}}>milestones</span> in near real time
                            </div>
                            <span style={{"height": "10px"}}></span>

                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/Track/1.svg`} alt=""/>
                                <span>Complete life cycle coverage </span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/Track/2.svg`} alt=""/>
                                <span>Comprehensive querying</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/Track/3.svg`} alt=""/>
                                <span>Dynamic user reports</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tb_content_prepare" className="tbContent text-theme" style={{"padding": "0px"}}>
                <div className="row">
                    <div className="col-lg-7">
                        <img className="tab_img_prepare" src={`${process.env.PUBLIC_URL}/assets/section_e/prepare/prepare.gif`} width="90%" alt="" style={{"borderRadius": "30px", "boxShadow": "0px 0px 20px 0px #61C7C1","border": "none"}}/>
                    </div>
                    <div className="col-lg-5">
                        <div className="flex-col tabRightContent tb_right_content_prepare">
                            <span className="tButton">
                                <strong>Prepare</strong>
                            </span>
                            <span style={{"height": "10px"}}></span>
                            <div style={{"fontSize": "25px", "fontweight": 600}}>
                                Prepare extensive <span style={{"color": "#61C7C1"}}>query</span> and <span style={{"color": "#61C7C1"}}>Reports</span>
                            </div>
                            <span style={{"height": "10px"}}></span>

                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/prepare/1.svg`} alt=""/>
                                <span>Easy report download </span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/prepare/2.svg`} alt=""/>
                                <span>Add queries to get faster project info</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/analyze/3.svg`} alt=""/>
                                <span>Add views to see multiple project reports</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tb_content_analyze" className="tbContent text-theme" style={{"padding": "0px"}}>
                <div className="row">
                    <div className="col-lg-7">
                        <img className="tab_img_analyze" src={`${process.env.PUBLIC_URL}assets/section_e/analyze/analyse.gif`} width="90%" alt="" style={{"borderRadius": "30px", "boxShadow": "0px 0px 20px 0px #61C7C1","border": "none"}}/>
                    </div>
                    <div className="col-lg-5">
                        <div className="flex-col tabRightContent tb_right_content_analyze">
                            <span className="tButton">
                                <strong>Analyze</strong>
                            </span>
                            <span style={{"height": "10px"}}></span>
                            <div style={{"fontSize": "25px", "fontweight": 600}}>
                                Analyse your data at various levels
                            </div>
                            <span style={{"height": "10px"}}></span>

                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/analyze/1.svg`} alt=""/>
                                <span> Get standardized data across Value, Capacity/Size</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/analyze/2.svg`} alt=""/>
                                <span>Evaluate the competitive landscapes</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/analyze/3.svg`} alt=""/>
                                <span>Assess project activity and completion rates</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tb_content_visualize" className="tbContent text-theme" style={{"padding": "0px"}}>
                <div className="row">
                    <div className="col-lg-7">
                        <img className="tab_img_visualize" src={`${process.env.PUBLIC_URL}assets/section_e/visualise/visualize.gif`} width="90%" alt=""style={{"borderRadius": "30px", "boxShadow": "0px 0px 20px 0px #61C7C1","border": "none"}}/>
                    </div>
                    <div className="col-lg-5">
                        <div className="flex-col tabRightContent tb_right_content_visualize">
                            <span className="tButton">
                                <strong>Visualize</strong>
                            </span>
                            <span style={{"height": "10px"}}></span>
                            <div style={{"fontSize": "25px", "fontweight": 600}}>
                                View your projects <span style={{"color": "#61C7C1"}}> real time </span> in multiple views
                            </div>
                            <span style={{"height": "10px"}}></span>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/visualise/1.svg`} alt=""/>
                                <span> Use form query or visual query to search for projects</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/visualise/2.svg`} alt=""/>
                                <span>Add multiple views of different sectors</span>
                            </div>
                            <div>
                                <img style={{"marginRight": "10px"}} src={`${process.env.PUBLIC_URL}assets/section_e/visualise/2.svg`} alt=""/>
                                <span>Submit query to get data on project across all values</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

function SectionF(){
    return (<div className="body" style={{"textAlign": "center", "paddingTop": "100px"}}>
    <h2 style={{"fontSize": "36px", "fontWeight": "bold", "textAlign": "center"}} className="text-theme">
        Governments and other stakeholders can use it to
    </h2>
    <span style={{"height": "80px"}}></span>
    <div className="row sectionF-anim">
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
            <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/1.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Monitor and evaluate progress</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Track projects and identify bottlenecks in different stages- from announcement to implementation and completion to commissioning
                </span>
            </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
        <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/2.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Conduct feasibity studies</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Assess project activity and completion rates to benchmark forecasts and anticipate project risks.
                </span>
            </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
        <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/3.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Competitor analysis</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Evaluate the competitive landscape with detailed company information for each project, including
                    nationality and role
                </span>
            </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
        <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/4.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Demand assessment</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Leverage standardized data across Value, Capacity/Sector and Timeframe to create accurate projections.
                </span>
            </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
            <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/5.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Gauge investment levels</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Measure capital investment and investment volumes by sector and stage of development.
                </span>
            </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 text-theme">
            <div style={{"margin": "20px","display": "flex","flexDirection": "column","justifyContent": "center"}}>
                <img height="100px" src={`${process.env.PUBLIC_URL}/assets/images/section_f/6.svg`} alt=""/>
                <span style={{"height": "30px"}}></span>
                <span style={{"fontSize": "20px","fontWeight": "bold"}}>Generate pre built projections</span>
                <span style={{"height": "20px"}}></span>
                <span style={{"fontSize": "14px"}}>
                    Create queries to get standardized data on each project with time frame, completion rate and any
                    associated data metrics.
                </span>
            </div>
        </div>
    </div>
</div>);
}

function SectionI(){
    return (
        <div className="body" style={{"minHeight": "100vh"}}>
            <div className="x-card" style={{"backgroundColor": "#61C7C1","borderRadius": "30px","padding": "80px 50px"}}>
                <div className="row">
                    <div className="col-lg-7">
                        <div style={{"display":"flex","flexDirection":"column","justifyContent":"center","height":"80%","gap":"30px"}}>
                            <div style={{"color": "white","fontSize":"30px","fontWeight": 500,"fontSize":"40px"}}>Ready to use our Dashboard</div>
                            <div style={{"fontSize": "25px"}}>Get access to pre-built analytics and project reports for 1,00,000+ infrastructure projects</div>
                            <span style={{"fontWeight": 600, "backgroundColor": "white", "color": "black", "borderRadius": "10px", "padding": "10px 30px","maxWidth": "260px"}}>
                                Get your free trial today!
                            </span>
                            <div>Have a query? Contact us</div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <img src={`${process.env.PUBLIC_URL}assets/section_i/dashboard2.gif`} width="100%" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SectionJ(){
    return (
        <div className="body">
            <h2 className="text-theme" style={{"fontSize": "36px","fontWeight": "bold","textAlign": "center"}}>Our Team</h2>
            <span style={{"height": "30px"}}></span>
            <h2 style={{"fontSize": "25px","fontWeight": "bold","textAlign": "center"}} className="text-theme">Management</h2>
            <span style={{"height": "30px"}}></span>

            {/***<div className="row sectionI-anim">
                <div id="thillai" className="col-sm-6 col-md-4 col-lg-3 flip-card">
                    <div className="flip-card-inner">
                        <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                            <img src={Thillai_Img} width="100%" alt=""/>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme">Dr. A.Thillai Rajan</span>
                            <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme">
                                Co-Founder & Director
                                Professor, IIT Madras
                                Associate
                            </span>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-back-inner">
                                <div className="flip-card-back-inner-title">Dr. A.Thillai Rajan</div>
                                <div className="flip-card-back-inner-desc">
                                    An accidental academic. Currently Professor in the Department of Management Studies at
                                    IIT Madras. Academic Associate at Harvard Kennedy School, Harvard University. Has been
                                    doing extensive research on the Indian Venture Capital Industry.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sethuraman" className="col-sm-6 col-md-4 col-lg-3 flip-card">
                    <div className="flip-card-inner">
                        <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                            <img src={Sethuraman_Img} width="100%" alt=""/>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme">Sethuraman Ramabadran</span>
                            <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme"> Co-Founder & Director – Akara Research & Technologies Pvt Ltd </span>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-back-inner">
                                <div className="flip-card-back-inner-title">Sethuraman Ramabadran</div>
                                <div className="flip-card-back-inner-desc">
                                    An Experienced & well networked professional in data-driven, decision-making
                                    products/applications industry. Management Graduate. Deep understanding on leveraging
                                    technology for executing ground level data collection & operations. Dabbles in Political
                                    commentator in Tamil Nadu.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="somasundaram" className="col-sm-6 col-md-4 col-lg-3 flip-card">
                    <div className="flip-card-inner">
                        <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                            <img src={Somasundaram_Img} width="100%" alt=""/>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme">Somasundaram Lakshminarasimhan</span>
                            <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme">Mentor</span>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-back-inner">
                                <div className="flip-card-back-inner-title">Somasundaram Lakshminarasimhan</div>
                                <div className="flip-card-back-inner-desc">
                                    Has been leading a team of economic analysts, experts in the fields of environment and IoT,
                                    and information & communications technology to build solutions that remove problem of
                                    information asymmetry to make governance decisions
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="rohit" className="col-sm-6 col-md-4 col-lg-3 flip-card">
                    <div className="flip-card-inner">
                        <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                            <img src={Rohit_Img} width="100%" alt=""/>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme">Rohit Sabharwal</span>
                            <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme"> Mentor </span>
                        </div>
                        <div className="flip-card-back">
                            <div className="flip-card-back-inner">
                                <div className="flip-card-back-inner-title">Rohit Sabharwal</div>
                                <div className="flip-card-back-inner-desc">
                                    A business leader in developing & marketing data-driven, decision-making
                                    products/applications. Economist by education. Rich experience in creation & execution of
                                    large data-driven solutions for wide range of customers.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>***/}

            <div className="row sectionI-anim justify-content-center ">
                <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                    <div id="thillai" className='flip-card'>
                        <div className="flip-card-inner">
                            <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_j/thillai.png`} width="100%" alt=""/>
                                <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme mt-2">Dr. A.Thillai Rajan</span>
                                <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme">
                                    Co-Founder & Director
                                    Professor, IIT Madras
                                    Associate
                                </span>
                            </div>
                            <div className="flip-card-back">
                                <div className="flip-card-back-inner">
                                    <div className="flip-card-back-inner-title">Dr. A.Thillai Rajan</div>
                                    <div className="flip-card-back-inner-desc">
                                        An accidental academic. Currently Professor in the Department of Management Studies at
                                        IIT Madras. Academic Associate at Harvard Kennedy School, Harvard University. Has been
                                        doing extensive research on the Indian Venture Capital Industry.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                    <div id="sethuraman" className='flip-card'>
                        <div className="flip-card-inner">
                            <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_j/sethuraman.png`} width="100%" alt=""/>
                                <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme mt-2">Sethuraman Ramabadran</span>
                                <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme"> Co-Founder & Director – Akara Research & Technologies Pvt Ltd </span>
                            </div>
                            <div className="flip-card-back">
                                <div className="flip-card-back-inner">
                                    <div className="flip-card-back-inner-title">Sethuraman Ramabadran</div>
                                    <div className="flip-card-back-inner-desc">
                                        An Experienced & well networked professional in data-driven, decision-making
                                        products/applications industry. Management Graduate. Deep understanding on leveraging
                                        technology for executing ground level data collection & operations. Dabbles in Political
                                        commentator in Tamil Nadu.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                    <div id="somasundaram" className='flip-card'>
                        <div className="flip-card-inner">
                            <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                                <img src={Somasundaram_Img} width="100%" alt=""/>
                                <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme mt-2">Somasundaram Lakshminarasimhan</span>
                                <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme">Mentor</span>
                            </div>
                            <div className="flip-card-back">
                                <div className="flip-card-back-inner">
                                    <div className="flip-card-back-inner-title">Somasundaram Lakshminarasimhan</div>
                                    <div className="flip-card-back-inner-desc">
                                        Has been leading a team of economic analysts, experts in the fields of environment and IoT,
                                        and information & communications technology to build solutions that remove problem of
                                        information asymmetry to make governance decisions
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='col-12 col-sm-6 col-md-4 col-lg-3'>
                    <div id="rohit" className='flip-card'>
                        <div className="flip-card-inner">
                            <div style={{"margin": "10px 0px"}} className="flex-col flip-card-front">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/section_j/rohit.png`} width="100%" alt=""/>
                                <span style={{"fontWeight": "bold","textAlign": "left","fontSize": "20px"}} className="text-theme mt-2">Rohit Sabharwal</span>
                                <span style={{"fontSize": "14px","textAlign": "left"}} className="text-theme"> Mentor </span>
                            </div>
                            <div className="flip-card-back">
                                <div className="flip-card-back-inner">
                                    <div className="flip-card-back-inner-title">Rohit Sabharwal</div>
                                    <div className="flip-card-back-inner-desc">
                                        A business leader in developing & marketing data-driven, decision-making
                                        products/applications. Economist by education. Rich experience in creation & execution of
                                        large data-driven solutions for wide range of customers.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SectionL(){ // News

    return (<div className="body mediaSection" style={{"minHeight": "900px"}}>
    <div>
        <center>
            <span className="text-theme">Get to know us</span>
            <div style={{"fontWeight": "500", "fontSize": "30px"}} className="text-theme">IN THE MEDIA</div>
        </center>
    </div>
    <div style={{"height": "40px"}}></div>
    <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
            <a href="https://www.thehindu.com/news/cities/mumbai/new-database-launched-to-track-infrastructure-projects-across-country/article27358729.ece" target="_blank">
                <div className="newsCard">
                    <div style={{"display": "flex","flexDirection": "row","justifyContent": "space-between"}}>
                        <div style={{"display": "flex","flexDirection": "column","alignItems": "flex-start","textAlign": "left"}}>
                            <div>
                                <img height="30px" width="30px" src={`${process.env.PUBLIC_URL}/assets/images/section_l/the_hindu.png`} alt="" />
                                <span style={{"fontWeight": "bold","marginLeft": "10px"}} className="text-theme">The Hindu</span>
                            </div>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "white", "fontWeight":"600"}} className="text-theme">New database launched to track infrastructure projects across country</span>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "#61C7C1", "fontSize":"14px"}} className="text-theme">Thillai Rajan A. & Akash Deep</span>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/media/media_img1.png`} height="150px" width="150px" alt="" style={{"objectFit": "fit"}} />
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
            <a href="https://www.livemint.com/opinion/online-views/opinion-how-funding-from-multilateral-agencies-aids-road-projects-1548613444238.html"
                target="_blank">
                <div className="newsCard">
                    <div style={{"display": "flex","flexDirection": "row","justifyContent": "space-between"}}>
                        <div style={{"display": "flex","flexDirection": "column","alignItems": "flex-start"}}>
                            <div>
                                <img height="30px" width="30px" src={`${process.env.PUBLIC_URL}/assets/images/section_l/live_mint.png`} alt="" />
                                <span style={{"fontWeight": "bold","marginLeft": "10px"}} className="text-theme">Live Mint</span>
                            </div>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "white", "fontWeight":"600"}} className="text-theme">How funding from multilateral agencies aids road projects</span>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "#61C7C1", "fontSize":"14px"}} className="text-theme">Thillai Rajan A. & Akash Deep</span>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/media/media_img2.png`} height="150px" width="150px" alt="" style={{"objectFit": "fit"}} />
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
            <a href="https://www.thehindubusinessline.com/opinion/more-highways-should-be-built-by-states/article29401496.ece"
                target="_blank">
                <div className="newsCard">
                    <div style={{"display": "flex","flexDirection": "row","justifyContent": "space-between"}}>
                        <div style={{"display": "flex","flexDirection": "column","alignItems": "flex-start"}}>
                            <div>
                                <img height="30px" width="30px" src={`${process.env.PUBLIC_URL}/assets/images/section_l/buisiness_line.png`} alt="" />
                                <span style={{"fontWeight": "bold","marginLeft": "10px"}} className="text-theme">Business Line</span>
                            </div>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "white", "fontWeight":"600"}} className="text-theme">More highways should be built by States</span>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "#61C7C1", "fontSize":"14px"}} className="text-theme">Thillai Rajan A. & Akash Deep</span>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/media/media_img3.png`} height="150px" width="150px" alt="" style={{"objectFit": "fit"}} />
                    </div>
                </div>
            </a>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
            <a href="https://www.livemint.com/Opinion/ZfpXfkS6Q8NO45hGV26NlM/Opinion--The-conundrum-of-PPP-road-projects.html" target="_blank">
                <div className="newsCard">
                    <div style={{"display": "flex","flexDirection": "row","justifyContent": "space-between", "flexWrap": "wrap"}}>
                        <div style={{"display": "flex","flexDirection": "column","alignItems": "flex-start"}}>
                            <div>
                                <img height="30px" width="30px" src={`${process.env.PUBLIC_URL}/assets/images/section_l/live_mint.png`} alt="" />
                                <span style={{"fontWeight": "bold","marginLeft": "10px"}} className="text-theme">Live Mint</span>
                            </div>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "white", "fontWeight":"600"}} className="text-theme">The conundrum of PPP road projects</span>
                            <span style={{"height": "20px"}}></span>
                            <span style={{"color": "#61C7C1", "fontSize":"14px"}} className="text-theme">Thillai Rajan A, Akash Deep, Samhita Mangu</span>
                        </div>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/media/media_img4.png`} height="150px" width="150px" alt="" style={{"objectFit": "fit"}} />
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>);
}

function Footer(){
    return (
        <div className="body" style={{"background": "#1D3348","padding": "0px 5vw"}}>
            <div className="flex-row-wrap" style={{"justifyContent": "space-between"}}>
                <div style={{"color": "white"}} className="text-theme">info@akara.co.in</div>
                <div className="nav-logo">IDIP</div>
            </div>
            <div style={{"height": "1px","backgroundColor": "white"}} className="button-filled-theme"></div>
            <div style={{"height": "20px"}}></div>
            <div className="flex-row-wrap" style={{"justifyContent": "center","paddingBottom": "20px"}}>
                <div style={{"color": "white"}} className="text-theme">©{new Date().getFullYear()} All rights reserved</div>
            </div>
        </div>
    );
}