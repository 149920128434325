import Axios from 'axios';
import { SERVER_BASE_URL, SERVER_BASE_URL_ROAD, SERVER_BASE_URL_WS, PAGE_ID } from '../constants';
import _ from 'lodash';
import jQ from 'jquery';
import { getLoginUserID, getLoginUserTypeId, convertQueryToObject } from './functions.utils';
import packageJson from '../../package.json';

export const Server = {
    API: 1,
    ROAD_API: 2,
    WSAPI_API: 3,
};

const LoggingApi = Axios.create({
    baseURL: SERVER_BASE_URL,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

const GLOBAL_PAYLOAD = [];

let logTimer;
function sendConsolidatedLog() {
    if (logTimer) clearTimeout(logTimer);
    let previous_page_id = localStorage.getItem('previous_page_id')
    let logintime = localStorage.getItem('logintime')
    logTimer = setTimeout(() => {
        if (GLOBAL_PAYLOAD.length > 0) {
            const consolidatedPayload = {
                current_login_user_id: getLoginUserID(),
                current_login_user_type: getLoginUserTypeId(),
                current_page_id: PAGE_ID[window.location.pathname],
                previous_page_id: previous_page_id ? previous_page_id : -1,
                payload: JSON.stringify([...GLOBAL_PAYLOAD]),
                logintime: logintime
            };
            
            const urlEncodedPayload = jQ.param(consolidatedPayload);

            LoggingApi.post('', urlEncodedPayload, { params: { 'for': 'saveActionLog' } })
            .then(({ data, status, ...res }) => {
                if(data && data.meta) {
                    localStorage.setItem('previous_page_id', data.meta.data?.previous_page_id ? data.meta.data.previous_page_id : -1)
                }
                GLOBAL_PAYLOAD.length = 0;
            }).then((res) => {
                
            })
            .catch((err) => console.error('Log API failed:', err));
        }
    }, 1000);
}

const ApiWithInterceptor = Axios.create();

ApiWithInterceptor.interceptors.request.use(
    (config) => {
        const payload = {
            data: convertQueryToObject(config.data) || {},
            for: config.params.for || '',
        };

        if (payload.data.hasOwnProperty('current_login_user_id') && payload.data.hasOwnProperty('current_login_user_type')) {
            delete payload.data["current_login_user_id"];
            delete payload.data["current_login_user_type"];
            delete payload.data["wv"];
            delete payload.data["_LID"];
        }

        GLOBAL_PAYLOAD.push(payload);

        return config;
    },
    (error) => Promise.reject(error)
);

ApiWithInterceptor.interceptors.response.use(
    (response) => {
        // sendConsolidatedLog();
        return response;
    },
    (error) => {
        // sendConsolidatedLog();
        return Promise.reject(error);
    }
);

export const Api = Axios.create({
    baseURL: SERVER_BASE_URL,
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['wv'] = packageJson.version;
        data['_LID'] = getLoginUserID();
        return jQ.param(data);
    }],
});

// Add interceptors to ApiRoad
Api.interceptors.request.use(
    (config) => {
        const payload = {
            data: config.data,
            for: config.params?.for || '',
        };

        if (payload.data.hasOwnProperty('current_login_user_id')) {
            delete payload.data["current_login_user_id"];
            delete payload.data["current_login_user_type"];
            delete payload.data["wv"];
            delete payload.data["_LID"];
        }

        GLOBAL_PAYLOAD.push(payload);
        console.log('Request Config:', config.url, config.params, config.data); // Debug log
        return config;
    },
    (error) => Promise.reject(error)
);

Api.interceptors.response.use(
    (response) => {
        // sendConsolidatedLog();
        return response;
    },
    (error) => {
        // sendConsolidatedLog();
        return Promise.reject(error);
    }
);

export const ApiRoad = Axios.create({
    baseURL: SERVER_BASE_URL_ROAD,
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['current_login_user_type'] = getLoginUserTypeId();
        data['_LID'] = getLoginUserID();
        data['wv'] = packageJson.version;
        return jQ.param(data);
    }],
});

// Add interceptors to ApiRoad
ApiRoad.interceptors.request.use(
    (config) => {
        const payload = {
            data: config.data,
            for: config.params?.for || '',
        };

        if (payload.data.hasOwnProperty('current_login_user_id')) {
            delete payload.data["current_login_user_id"];
            delete payload.data["current_login_user_type"];
            delete payload.data["wv"];
            delete payload.data["_LID"];
        }

        GLOBAL_PAYLOAD.push(payload);
        return config;
    },
    (error) => Promise.reject(error)
);

ApiRoad.interceptors.response.use(
    (response) => {
        // sendConsolidatedLog();
        return response;
    },
    (error) => {
        // sendConsolidatedLog();
        return Promise.reject(error);
    }
);

export const ApiWs = Axios.create({
    baseURL: SERVER_BASE_URL_WS,
    transformRequest: [(data, headers) => {
        data['current_login_user_id'] = getLoginUserID();
        data['current_login_user_type'] = getLoginUserTypeId();
        data['wv'] = packageJson.version;
        return jQ.param(data);
    }],
});

// Add interceptors to ApiRoad
ApiWs.interceptors.request.use(
    (config) => {
        const payload = {
            data: config.data,
            for: config.params?.for || '',
        };

        if (payload.data.hasOwnProperty('current_login_user_id')) {
            delete payload.data["current_login_user_id"];
            delete payload.data["current_login_user_type"];
            delete payload.data["wv"];
            delete payload.data["_LID"];
        }

        GLOBAL_PAYLOAD.push(payload);
        return config;
    },
    (error) => Promise.reject(error)
);

ApiWs.interceptors.response.use(
    (response) => {
        // sendConsolidatedLog();
        return response;
    },
    (error) => {
        // sendConsolidatedLog();
        return Promise.reject(error);
    }
);

// Request Wrapper
export const Request = {
    async post(requestFor, data = {}, ApiFor = Server.API, objConfig = {}) {
        const param_data = this._convertObjToParams(data);
        const base_url = this._getBaseUrl(ApiFor);

        const defaultParams = {
            baseURL: base_url,
            method: 'POST',
            data: param_data,
            params: { for: requestFor, _LID: getLoginUserID() },
            ...objConfig,
        };

        return await ApiWithInterceptor(defaultParams).then(({ data, status, ...res }) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request');
            } else {
                return Promise.resolve(data);
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },

    async get(requestFor, data = {}, ApiFor = Server.API) {
        const base_url = this._getBaseUrl(ApiFor);
        data['for'] = requestFor;
        data['current_login_user_id'] = getLoginUserID();
        data['_LID'] = getLoginUserID();

        return await ApiWithInterceptor({
            baseURL: base_url,
            method: 'GET',
            params: data,
        }).then(({ data, status, ...res }) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request');
            } else {
                return Promise.resolve(data);
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });

    },

    async uploadFiles(requestFor, data, ApiFor = Server.API) {
        const base_url = this._getBaseUrl(ApiFor);
        data['current_login_user_id'] = getLoginUserID();
        data['wv'] = packageJson.version;

        return await ApiWithInterceptor({
            baseURL: base_url,
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'POST',
            data: data,
            params: { for: requestFor, _LID: getLoginUserID() },
        }).then(({ data, status, ...res }) => {
            if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
                throw new Error(_.get(data, 'meta.msg') || 'Bad Request')
            } else {
                return Promise.resolve(_.get(data, 'meta'));
            }
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },

    async downloadFiles(objparams, ApiFor = Server.API) {
        const { url, data: data_params, filename, requestFor } = objparams;
        let param_data = this._convertObjToParams(data_params);
        const base_url = this._getBaseUrl(ApiFor);

        return await ApiWithInterceptor({
            baseURL: base_url,
            method: 'POST',
            data: param_data,
            params: { 'for': requestFor },
            responseType: 'blob'
        }).then(({ data, ...res }) => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', filename || 'unknown_files');
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 10000);
            return Promise.resolve(true);
        }).catch(err => {
            return Promise.reject(err.toString());
        });
    },

    _convertObjToParams(data) {
        if (_.isPlainObject(data)) {
            data['current_login_user_id'] = getLoginUserID();
            data['current_login_user_type'] = getLoginUserTypeId();
            data['wv'] = packageJson.version;
            data['_LID'] = getLoginUserID();
            return jQ.param(data);
        }
        return '';
    },

    _validateResponse(data) {
        if (!_.has(data, 'meta') || !_.has(data, 'meta.error') || _.get(data, 'meta.error') == 1) {
            throw new Error(_.get(data, 'meta.msg') || 'Bad Request');
        } else {
            return Promise.resolve(data);
        }
    },

    _getBaseUrl(ApiFor) {
        switch (ApiFor) {
            case Server.API:
                return SERVER_BASE_URL;
            case Server.ROAD_API:
                return SERVER_BASE_URL_ROAD;
            case Server.WSAPI_API:
                return SERVER_BASE_URL_WS;
            default:
                throw new Error('Invalid API Type');
        }
    },
};

// Default Export
export default { Api, ApiRoad, ApiWs, Request, Server };
