import React, { useEffect, useRef, useState, Fragment } from 'react';
import _ from 'lodash';
import { Card, CardHeader, Button, CardBody, Row, Col, Progress, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import CountUp from 'react-countup';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

const PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE = 25;

HighchartsMore(Highcharts);

export function UserDashboardCardContainer(props) {

	const { data, refreshDashboardUserData, userRights: { DASHBOARD }, downloadRoadExcelFileData, exportRoadData } = props;

	const [isExapandedCard, setExpandedCard] = useState(true);

	const expandToggle = (e) => {
		e.preventDefault();
		if (!isExapandedCard) {
			refreshDashboardUserData();
		}
		setExpandedCard(!isExapandedCard);
	}

	const resDataExportEvent = (e) => {
		e.preventDefault();
		downloadRoadExcelFileData();
	}


	return (
		<>
		<Card className="rounded-0 bg-transparent mb-4">
			<CardHeader className="rounded-0 mb-1 border-bottom d-flex align-items-center justify-content-between dboard-heading">
				<h5 className="display font-weight-bold bg-white mb-0 d-inline-block"><FontAwesomeIcon icon={['fas', 'users']} />&nbsp; User Info</h5>

				<div>
					{/* {DASHBOARD.USER_DBOARD_EXPORT_BTN ? (
						<Fragment>
							<Button size="sm" color="info" id="export_data_btn" onClick={resDataExportEvent} className="mr-2">
								<span className="btn-wrapper--icon">
									<FontAwesomeIcon icon={['fas', 'file-excel']} className="opacity-8 font-size-xs" />
								</span>
							</Button>
							<UncontrolledTooltip popperClassName={'tooltip-info'} placement="top-end" target="export_data_btn">
								Export Data To Excel
							</UncontrolledTooltip>
						</Fragment>
					) : null} */}
				</div>
			</CardHeader>
			<CardBody className="p-2" >
				<RoadDashboardExpandedCard {...props} isExapandedCard={isExapandedCard} />
			</CardBody>
		</Card>
		</>
	);
}

function RoadDashboardExpandedCard(props) {

	const { data, road_loading, isExapandedCard, period_count } = props;

	const road = data;

	return (
		<Fragment>
			<Row className="dashboard-sm justify-content-center">
				<NoOfProjectsCountAndAmt {...props} />

				<Col md={12} className="mt-3">
					<ModeandStatusCountAndAmt {...props} />
				</Col>

			</Row>
		</Fragment>
	);
}

function CardsDetails(props) {

	const { total, amt, title, faicon, isProgress, progressClassName, percent, barSpace, isLoading, children, onClick, progressColor } = props;
	let barChartSpace = !_.isNil(barSpace) ? barSpace : true;
	let percentage = total / percent * 100;
	return (
		<Card className={cx("card-list-box btn px-3 dboard-status-card-box", children ? "py-3" : "py-4")}>
			<div className="d-flex align-items-center" >
				{faicon ?
					(
						<div className="pr-3 status-left-content">
							{isLoading ? <Skeleton width="50px" height="50px" /> : <FontAwesomeIcon icon={faicon} className="text-primary" size="3x" />}
						</div>
					)
					: null}
				<div className="flex-grow-1 pl-2 status-right-content" onClick={() => onClick}>
					<h5 className="border-bottom pb-3 mb-3 font-weight-bold text-left" style={{ fontSize: '0.85rem' }}> {isLoading ? <Skeleton /> : title}</h5>
					{children ? children : (
						<Fragment>
							<div className="d-flex align-items-center pb-4 justify-content-between">
								<div>
									{isLoading ? <Skeleton width="150px" /> : (
										<div className="cost-amt-fsize" >
											<CountUp
												start={0}
												end={total || 0}
												duration={3}
												deplay={2}
												separator=""
												decimals={0}
											/>
										</div>
									)
									}
								</div>
								<div className="cost-amt-fsize text-second">
									{isLoading ? <Skeleton width="150px" /> : <>
									{/* <FontAwesomeIcon icon={['fas', 'rupee-sign']} size="sm" />&nbsp;{amt}<small className="cost-cr-fsize">Crs</small> */}
									 </>}
								</div>
							</div>
						</Fragment>
					)}

					{isProgress ? (
						<div>
							{isLoading ? <Skeleton width="100%" /> : (
								<>
									{/* <Progress className={cx("progress-animated-alt box-shadow-none", progressClassName ? progressClassName : '', { 'prelative': percent < PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE ? true : false })} value={percent}><span className="center">{percent}%</span></Progress> */}
									<div className={cx("progress progress-animated-alt box-shadow-none", { 'prelative': percentage < PROGRESS_CENTRE_ALIGNMENT_MIN_VALUE ? true : false })}>
										<div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`, background: progressColor ? progressColor : "" }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"> <span className="center">{parseFloat(percentage.toFixed(2))}%</span></div>
									</div>
								</>
							)}
						</div>
					) : barChartSpace ? (
						<div style={{ height: '1rem' }}>
							&nbsp;
						</div>
					) : null}
				</div>
			</div>

		</Card>
	);
}

function NoOfProjectsCountAndAmt(props) {
	const history = useHistory()
	const { data, road_loading, isExapandedCard, userCount } = props;

	//const road = _.has(data, 'road') ? data.road : {};
	
	const road = userCount;

	const gotTOProject = (e) => {
		if (e === 'All') {
			history.push('/manageusers')
		} else {
			if(e?.total_user && Number(e.total_user) > 0) {
				history.push('/manageusers', {id_user_type: e.id_user_type})
			}
		}
	}

	let data_load = []
	for (let i = 1; i < 10; i++) {
		data_load.push({
			name: i
		})
	}


	return (
		<>
			<Col md="3" className={cx("pb-3 ")} >
				<div onClick={() => gotTOProject('All')}>
					<CardsDetails title="Total Users" faicon={['fas', 'users']} total={road?.total_count?.length ? road?.total_count[0].total_user : 0} isLoading={road_loading} />
				</div>

			</Col>

			<Fragment>
				{road.count_data?.length > 0 ?
					<>
						{road.count_data?.map((e, i) =>

							<Col key={i} md={3} className={cx("pb-3 ")} >
								<div onClick={() => gotTOProject(e)}>
									<CardsDetails key={i} title={e?.user_type} faicon={[e.fas_icon, e.fas_svg]} total={e.total_user || 0} progressClassName="underbidding-progress" progressColor={e?.color} isProgress={true} percent={road?.total_count?.length ? road?.total_count[0].total_user : 0} isLoading={road_loading} />
								</div>
							</Col>
						)} </> :
					<>
						{data_load.map((e, i) =>
							<Col key={i} md={3} className={cx("pb-3 ")} >
								<div onClick={() => gotTOProject(e)}>
									<CardsDetails title={e?.name} faicon={[e.fas_icon, e.fas_svg]} total={e.total || 0} progressClassName="underbidding-progress" progressColor='red' isProgress={true} percent={e.percent} isLoading={road_loading} />
								</div>
							</Col>)}
					</>}
				<div className='clearfix'></div>
			</Fragment>
	
		</>
	);
}


function ModeandStatusCountAndAmt(props) {
	const history = useHistory()
	const { subscription_data, status } = props;
	const [showPrice, setShowPrice] = useState(false);
	const [rSelected, setRSelected] = useState(2);
	const status_data = subscription_data;
	
	const gotTOProject = (e, status) => {
		history.push('/manageusers', {id_user_type: e.id_user_type, expiry_type: status})
	}

	return (
		<>
			<Card className="card-list-box">
				<CardHeader className="rounded-0 mb-1 border-bottom d-flex align-items-center justify-content-between dboard-heading pt-0">
					<h5 className="font-weight-bold bg-white mb-0 d-inline-block" style={{ fontSize: '1rem' }}>Subscription Period</h5>
				</CardHeader>
				<CardBody className="p-0">
					<table className="table table-bordered table-custom mb-0 status-table">
						<thead>
							<tr>
								<th style={{ color: "#2B2B2B", textAlign: "center", textWrap: 'nowrap', borderBottomColor: 'rgb(207, 231, 252)' }}>User Type</th>
								<th style={{ color: "#2B2B2B", textAlign: "center", borderBottomColor: 'rgb(207, 231, 252)' }} >Active</th>
								<th style={{ color: "#2B2B2B", textAlign: "center", borderBottomColor: 'rgb(207, 231, 252)' }} >Expired</th>
							</tr>
						</thead>
						<tbody>
							{(status_data && status_data.length > 0) && status_data.map((e, index) => (
								<tr key={index}>
									<td width="30%" style={{ color: "#2B2B2B", fontSize: '13px', fontWeight: 500, textWrap: 'nowrap', backgroundColor: '#f3f9ff', textTransform: 'uppercase', borderRightColor: 'rgb(207, 231, 252)', borderRightWidth: '2px', height: 'auto', cursor: 'default'}}>{e.user_type}</td>
									<td style={{ textAlign: 'center', height: 'auto', cursor: 'default' }}>
										{Number(e.count_active) > 0 ? <p style={{cursor: 'pointer' }} onClick={() => gotTOProject(e, 1)}>{e.count_active} </p> : '-' }
									</td>
									<td style={{ textAlign: 'center', height: 'auto', cursor: 'default' }}>
										{Number(e.count_expired) > 0 ? <p style={{cursor: 'pointer' }} onClick={() => gotTOProject(e, 2)}>{e.count_expired}</p> : '-'}
									</td>
								</tr>
							))}
						</tbody>
					</table>

				</CardBody>
			</Card>
		</>
	);
}
