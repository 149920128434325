import React, { Fragment, Component } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import _ from 'lodash';

class PageTitle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {

        let {
            pageTitleClassName,
            pageTitleStyle,
            pageTitleBackground,
            pageTitleShadow,
            pageTitleBreadcrumb,
            //pageTitleIconBox,
            pageTitleDescription,
            children
        } = this.props;

        return (
            <Fragment>
                <div className={cx("app-page-title", pageTitleStyle, pageTitleBackground, pageTitleClassName, { 'app-page-title--shadow': pageTitleShadow })} >
                    <div style={{width:this?.props?.sub?'70%':''}}>
                        {pageTitleBreadcrumb && (
                            <ol className="app-page-title--breadcrumb breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <FontAwesomeIcon icon={['fas', 'tachometer-alt']} />
                                    </Link>
                                </li>
                                <li className="breadcrumb-item"><Link to="/DashboardDefault">Dashboards</Link></li>
                                <li className="breadcrumb-item active">
                                    <span>{this.props.titleHeading}</span>

                                </li>
                            </ol>
                        )}

                        <div className="app-page-title--first">
                            {/***pageTitleIconBox && (
                                <div className="app-page-title--iconbox d-70">
                                    <div className="d-70 d-flex gradient-icon align-items-center justify-content-center">
                                        <i className="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                                    </div>
                                </div>
                            )***/}
                            <div className="app-page-title--heading" >
                                <h1>{this.props.titleHeading} <span style={{color:'green'}}>{this?.props?.sub??''}</span></h1>
                                { !_.isNil(pageTitleDescription) && !_.isEmpty(pageTitleDescription)  && (
                                    <div className="app-page-title--description">
                                        {this.props.titleDescription}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center title-right-side-container">
                        {children}
                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = state => ({
    pageTitleStyle: state.ThemeOptions.pageTitleStyle,
    pageTitleBackground: state.ThemeOptions.pageTitleBackground,
    pageTitleShadow: state.ThemeOptions.pageTitleShadow,
    pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
    pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
    pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);