import { GET_DBOARD_ROAD_DATA_REQUEST_INIT, GET_DBOARD_ROAD_DATA_REQUEST_DONE, GET_DBOARD_ROAD_DATA_REQUEST_FAILURE, GET_DBOARD_METRO_DATA_REQUEST_INIT, GET_DBOARD_METRO_DATA_REQUEST_DONE, GET_DBOARD_METRO_DATA_REQUEST_FAILURE, GET_DBOARD_WS_DATA_REQUEST_INIT, GET_DBOARD_WS_DATA_REQUEST_DONE, GET_DBOARD_WS_DATA_REQUEST_FAILURE,
    GET_DBOARD_USER_DATA_REQUEST_INIT, GET_DBOARD_USER_DATA_REQUEST_DONE, GET_DBOARD_USER_DATA_REQUEST_FAILURE
 } from "../constants";

const initState = {
    loading: false,
    error: false,
    msg: '',
    data: {},
    road: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    user: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    metro: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    },
    waterSanitation: {
        loading: false,
        error: false,
        msg: '',
        data: {}
    }
};

export const Dashboard = (state = initState, action) => {
    switch (action.type) {
        case GET_DBOARD_ROAD_DATA_REQUEST_INIT:
            return Object.assign({}, state, { road: { loading: true, error: false, data: {} } });
        case GET_DBOARD_ROAD_DATA_REQUEST_DONE:
            return Object.assign({}, state, { road: { loading: false, error: false, data: action.payload } });
        case GET_DBOARD_ROAD_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { road: { loading: false, error: true, data: {} } });
        
        case GET_DBOARD_METRO_DATA_REQUEST_INIT:
            return Object.assign({}, state, { metro: { loading: true, error: false, data: {} } });
        case GET_DBOARD_METRO_DATA_REQUEST_DONE:
            return Object.assign({}, state, { metro: { loading: false, error: false, data: action.payload } });
        case GET_DBOARD_METRO_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { metro: { loading: false, error: true, data: {} } });
        
        case GET_DBOARD_WS_DATA_REQUEST_INIT:
            return Object.assign({}, state, { waterSanitation: { loading: true, error: false, data: {} } });
        case GET_DBOARD_WS_DATA_REQUEST_DONE:
            return Object.assign({}, state, { waterSanitation: { loading: false, error: false, data: action.payload } });
        case GET_DBOARD_WS_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { waterSanitation: { loading: false, error: true, data: {} } });      
        case GET_DBOARD_USER_DATA_REQUEST_INIT:
            return Object.assign({}, state, { user: { loading: true, error: false, data: {} } });
        case GET_DBOARD_USER_DATA_REQUEST_DONE:
            return Object.assign({}, state, { user: { loading: false, error: false, data: action.payload } });
        case GET_DBOARD_USER_DATA_REQUEST_FAILURE:
            return Object.assign({}, state, { user: { loading: false, error: true, data: {} } });  
        
    }
    return state;
}